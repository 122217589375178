import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

const TambahJenisSetoran = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeJenisPajak, setKodeJenisPajak] = useState("");
  const [kodeJenisSetoran, setKodeJenisSetoran] = useState("");
  const [namaJenisSetoran, setNamaJenisSetoran] = useState("");
  const [ketJenisSetoran, setKetJenisSetoran] = useState("");

  // Kondisi Setoran
  const [masaBulan, setMasaBulan] = useState("");
  const [masaTahun, setMasaTahun] = useState("");
  const [mataUang, setMataUang] = useState("");
  const [wpBadan, setWpBadan] = useState("");
  const [wpPemungut, setWpPemungut] = useState("");
  const [wpOp, setWpOp] = useState("");
  const [npwpNol, setNpwpNol] = useState("");
  const [npwpLain, setNpwpLain] = useState("");
  const [butuhNop, setButuhNop] = useState("");
  const [butuhNosk, setButuhNosk] = useState("");

  const [jenisPajaks, setJenisPajaks] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let jenisPajakOptions = jenisPajaks.map((jenisPajak) => ({
    label: `${jenisPajak.kodeJenisPajak} - ${jenisPajak.namaJenisPajak}`,
  }));

  let masaBulanOptions = [
    {
      label: "1 - Input Masa Pajak Sampai Bulan Dikunci",
      id: 1,
    },
    {
      label: "2 - Hanya Input Tahun",
      id: 2,
    },
    {
      label: "3 - Input Masa Pajak Sampai Bulan Bisa Dipilih",
      id: 3,
    },
  ];

  let masaTahunOptions = [
    {
      label: "1 - ",
      id: 1,
    },
    {
      label: "2 - ",
      id: 2,
    },
  ];

  let mataUangOptions = [
    {
      label: "1 - ",
      id: 1,
    },
    {
      label: "2 - ",
      id: 2,
    },
  ];

  let wpBadanOptions = [
    {
      label: "1 - ",
      id: 1,
    },
  ];

  let wpPemungutOptions = [
    {
      label: "0 - ",
      id: 0,
    },
    {
      label: "1 - ",
      id: 1,
    },
  ];

  let wpOpOptions = [
    {
      label: "0 - ",
      id: 0,
    },
    {
      label: "1 - ",
      id: 1,
    },
  ];

  let npwpNolOptions = [
    {
      label: "0 - Tidak tampil NPWP",
      id: 0,
    },
    {
      label: "1 - Tampil NPWP",
      id: 1,
    },
    {
      label: "2 - ",
      id: 2,
    },
  ];

  let npwpLainOptions = [
    {
      label: "0 - Tidak muncul inputan pilihan Subjek Pajak",
      id: 0,
    },
    {
      label: "1 - Muncul inputan pilihan Subjek Pajak",
      id: 1,
    },
  ];

  let butuhNopOptions = [
    {
      label: "0 - Tidak muncul inputan NOP",
      id: 0,
    },
    {
      label: "1 - Muncul inputan NOP",
      id: 1,
    },
    {
      label: "2 - ",
      id: 2,
    },
  ];

  let butuhNoskOptions = [
    {
      label: "0 - Tidak muncul inputan Nomor Ketetapan",
      id: 0,
    },
    {
      label: "1 - Muncul inputan Nomor Ketetapan",
      id: 1,
    },
  ];

  useEffect(() => {
    getJenisPajakData();
  }, []);

  const getJenisPajakData = async () => {
    const response = await axios.post(`${tempUrl}/jenisPajaks`, {
      _id: user.id,
      token: user.token,
    });
    setJenisPajaks(response.data);
  };

  const saveJenisSetoran = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      kodeJenisPajak.length !== 0 &&
      masaBulan.length !== 0 &&
      masaTahun.length !== 0 &&
      mataUang.length !== 0 &&
      wpBadan.length !== 0 &&
      wpPemungut.length !== 0 &&
      wpOp.length !== 0 &&
      npwpNol.length !== 0 &&
      npwpLain.length !== 0 &&
      butuhNop.length !== 0 &&
      butuhNosk.length !== 0
    ) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveJenisSetoran`, {
          kodeJenisPajak,
          kodeJenisSetoran,
          namaJenisSetoran,
          ketJenisSetoran,

          masaBulan,
          masaTahun,
          mataUang,
          wpBadan,
          wpPemungut,
          wpOp,
          npwpNol,
          npwpLain,
          butuhNop,
          butuhNosk,

          timestamp: new Date(),

          userIdInput: user.id,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        });
        setLoading(false);
        navigate("/jenisSetoran");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Jenis Setoran</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={saveJenisSetoran}>
        <Card>
          <Card.Header>Jenis Setoran</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode Jenis Pajak <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={jenisPajakOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeJenisPajak.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeJenisPajak(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={kodeJenisSetoran}
                      onChange={(e) => setKodeJenisSetoran(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      as="textarea"
                      rows={4}
                      value={namaJenisSetoran}
                      onChange={(e) => setNamaJenisSetoran(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={ketJenisSetoran}
                      onChange={(e) => setKetJenisSetoran(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card style={spacingTop}>
          <Card.Header>Kondisi Setoran</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Masa Bulan <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={masaBulanOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && masaBulan.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setMasaBulan(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Masa Tahun <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={masaTahunOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && masaTahun.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setMasaTahun(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Mata Uang <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={mataUangOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && mataUang.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setMataUang(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Wp Badan <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={wpBadanOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && wpBadan.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setWpBadan(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Wp Pemungut <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={wpPemungutOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && wpPemungut.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setWpPemungut(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Wp Op <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={wpOpOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && wpOp.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setWpOp(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Npwp Nol <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={npwpNolOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && npwpNol.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setNpwpNol(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Npwp Lain <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={npwpLainOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && npwpLain.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setNpwpLain(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Butuh Nop <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={butuhNopOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && butuhNop.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setButuhNop(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Butuh No Sk <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={butuhNoskOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && butuhNosk.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setButuhNosk(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Box sx={spacingTop}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/jenisSetoran")}
            sx={{ marginRight: 2 }}
          >
            {"< Kembali"}
          </Button>
          <Button variant="contained" startIcon={<SaveIcon />} type="submit">
            Simpan
          </Button>
        </Box>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahJenisSetoran;

const spacingTop = {
  marginTop: "20px",
};

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};
