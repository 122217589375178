import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

const TambahObjekPajak = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeJenisPajak, setKodeJenisPajak] = useState("");
  const [kodeJenisSetoran, setKodeJenisSetoran] = useState("");
  const [kodeObjekPajak, setKodeObjekPajak] = useState("");
  const [namaObjekPajak, setNamaObjekPajak] = useState("");
  const [ketObjekPajak, setKetObjekPajak] = useState("");
  const [tarifPersen, setTarifPersen] = useState("");

  const [jenisPajaks, setJenisPajaks] = useState([]);
  const [jenisSetorans, setJenisSetorans] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let jenisPajakOptions = jenisPajaks.map((jenisPajak) => ({
    label: `${jenisPajak.kodeJenisPajak} - ${jenisPajak.namaJenisPajak}`,
  }));

  let jenisSetoranOptions = jenisSetorans.map((jenisSetoran) => ({
    label: `${jenisSetoran.kodeJenisSetoran} - ${jenisSetoran.namaJenisSetoran}`,
  }));

  useEffect(() => {
    getJenisPajakData();
  }, []);

  const getJenisPajakData = async () => {
    const response = await axios.post(`${tempUrl}/jenisPajaks`, {
      _id: user.id,
      token: user.token,
    });
    setJenisPajaks(response.data);
  };

  const getJenisSetoranData = async (kodeJenisPajak) => {
    const response = await axios.post(
      `${tempUrl}/jenisSetoransByKodeJenisPajak`,
      {
        kodeJenisPajak,
        _id: user.id,
        token: user.token,
      }
    );
    setJenisSetorans(response.data);
  };

  const findObjekPajakNextKode = async (kodeJenisPajak, kodeJenisSetoran) => {
    const response = await axios.post(`${tempUrl}/objekPajakNextKode`, {
      kodeJenisPajak,
      kodeJenisSetoran: kodeJenisSetoran.split(" ", 1)[0],
      _id: user.id,
      token: user.token,
    });
    if (response.data) {
      setKodeObjekPajak(response.data);
    } else {
      setKodeObjekPajak("");
    }
  };

  const saveObjekPajak = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && kodeJenisSetoran.length !== 0) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveObjekPajak`, {
          kodeJenisPajak,
          kodeJenisSetoran: kodeJenisSetoran.split(" ", 1)[0],

          kodeObjekPajak,
          namaObjekPajak,
          ketObjekPajak,
          tarifPersen,

          userIdInput: user.id,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        });
        setLoading(false);
        navigate("/objekPajak");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Objek Pajak</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={saveObjekPajak}>
        <Card>
          <Card.Header>Objek Pajak</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Kode Jenis Pajak <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={jenisPajakOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeJenisPajak.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeJenisPajak(value.split(" ", 1)[0]);

                        if (value.length === 0) {
                          setKodeJenisSetoran("");
                          setJenisSetorans([]);
                        } else {
                          getJenisSetoranData(value.split(" ", 1)[0]);
                        }
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Kode Jenis Setoran <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={jenisSetoranOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeJenisSetoran.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeJenisSetoran(value);

                        if (value.length === 0) {
                          setKodeObjekPajak("");
                        } else {
                          findObjekPajakNextKode(kodeJenisPajak, value);
                        }
                      }}
                      value={kodeJenisSetoran}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Kode <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      value={kodeObjekPajak}
                      onChange={(e) => setKodeObjekPajak(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Nama <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      as="textarea"
                      rows={4}
                      value={namaObjekPajak}
                      onChange={(e) => setNamaObjekPajak(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={ketObjekPajak}
                      onChange={(e) => setKetObjekPajak(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Tarif (%) <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      type="number"
                      value={tarifPersen}
                      onChange={(e) => setTarifPersen(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Box sx={spacingTop}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/objekPajak")}
            sx={{ marginRight: 2 }}
          >
            {"< Kembali"}
          </Button>
          <Button variant="contained" startIcon={<SaveIcon />} type="submit">
            Simpan
          </Button>
        </Box>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahObjekPajak;

const spacingTop = {
  marginTop: "20px",
};

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};
