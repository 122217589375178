import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Button, Snackbar, Alert } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const UbahObjekPajak = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeJenisPajak, setKodeJenisPajak] = useState("");
  const [kodeJenisSetoran, setKodeJenisSetoran] = useState("");
  const [kodeObjekPajak, setKodeObjekPajak] = useState("");
  const [namaObjekPajak, setNamaObjekPajak] = useState("");
  const [ketObjekPajak, setKetObjekPajak] = useState("");
  const [tarifPersen, setTarifPersen] = useState("");

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getObjekPajakById();
  }, []);

  const getObjekPajakById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/objekPajaks/${id}`, {
      _id: user.id,
      token: user.token,
    });
    setKodeJenisPajak(
      `${response.data.jenissetoran.jenispajak.kodeJenisPajak} - ${response.data.jenissetoran.jenispajak.namaJenisPajak}`
    );
    setKodeJenisSetoran(
      `${response.data.jenissetoran.kodeJenisSetoran} - ${response.data.jenissetoran.namaJenisSetoran}`
    );
    setKodeObjekPajak(response.data.kodeObjekPajak);
    setNamaObjekPajak(response.data.namaObjekPajak);
    setKetObjekPajak(response.data.ketObjekPajak);
    setTarifPersen(response.data.tarifPersen);
    setLoading(false);
  };

  const updateObjekPajak = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(`${tempUrl}/updateObjekPajak/${id}`, {
            namaObjekPajak,
            ketObjekPajak,
            tarifPersen,

            userIdUpdate: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          setLoading(false);
          navigate(`/objekPajak/${id}`);
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Objek Pajak</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={updateObjekPajak}>
        <Card>
          <Card.Header>Objek Pajak</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Kode Jenis Pajak :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={kodeJenisPajak} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Kode Objek Pajak :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={kodeJenisSetoran} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Kode :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control value={kodeObjekPajak} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Nama <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      as="textarea"
                      rows={4}
                      value={namaObjekPajak}
                      onChange={(e) => setNamaObjekPajak(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={ketObjekPajak}
                      onChange={(e) => setKetObjekPajak(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="5" style={textRight}>
                    Tarif (%) <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      required
                      type="number"
                      value={tarifPersen}
                      onChange={(e) => setTarifPersen(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Box style={spacingTop}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/objekPajak")}
            sx={{ marginRight: 2 }}
          >
            {"< Kembali"}
          </Button>
          <Button variant="contained" startIcon={<EditIcon />} type="submit">
            Edit
          </Button>
        </Box>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahObjekPajak;

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};

const spacingTop = {
  marginTop: "20px",
};
