import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableJenisSetoran } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Pagination, Button, ButtonGroup } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

const TampilJenisSetoran = () => {
  const ref = useRef(null);
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [kodeJenisPajak, setKodeJenisPajak] = useState("");
  const [kodeJenisSetoran, setKodeJenisSetoran] = useState("");
  const [namaJenisSetoran, setNamaJenisSetoran] = useState("");
  const [ketJenisSetoran, setKetJenisSetoran] = useState("");

  // Kondisi Setoran
  const [masaBulan, setMasaBulan] = useState("");
  const [masaTahun, setMasaTahun] = useState("");
  const [mataUang, setMataUang] = useState("");
  const [wpBadan, setWpBadan] = useState("");
  const [wpPemungut, setWpPemungut] = useState("");
  const [wpOp, setWpOp] = useState("");
  const [npwpNol, setNpwpNol] = useState("");
  const [npwpLain, setNpwpLain] = useState("");
  const [butuhNop, setButuhNop] = useState("");
  const [butuhNosk, setButuhNosk] = useState("");

  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [jenisSetorans, setJenisSetorans] = useState([]);
  const [jenisSetoransPagination, setJenisSetoransPagination] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  let masaBulanOptions = [
    {
      label: "1 - Input Masa Pajak Sampai Bulan Dikunci",
      id: 1,
    },
    {
      label: "2 - Hanya Input Tahun",
      id: 2,
    },
    {
      label: "3 - Input Masa Pajak Sampai Bulan Bisa Dipilih",
      id: 3,
    },
  ];

  let masaTahunOptions = [
    {
      label: "1 - ",
      id: 1,
    },
    {
      label: "2 - ",
      id: 2,
    },
  ];

  let mataUangOptions = [
    {
      label: "1 - ",
      id: 1,
    },
    {
      label: "2 - ",
      id: 2,
    },
  ];

  let wpBadanOptions = [
    {
      label: "1 - ",
      id: 1,
    },
  ];

  let wpPemungutOptions = [
    {
      label: "0 - ",
      id: 0,
    },
    {
      label: "1 - ",
      id: 1,
    },
  ];

  let wpOpOptions = [
    {
      label: "0 - ",
      id: 0,
    },
    {
      label: "1 - ",
      id: 1,
    },
  ];

  let npwpNolOptions = [
    {
      label: "0 - Tidak tampil NPWP",
      id: 0,
    },
    {
      label: "1 - Tampil NPWP",
      id: 1,
    },
    {
      label: "2 - ",
      id: 2,
    },
  ];

  let npwpLainOptions = [
    {
      label: "0 - Tidak muncul inputan pilihan Subjek Pajak",
      id: 0,
    },
    {
      label: "1 - Muncul inputan pilihan Subjek Pajak",
      id: 1,
    },
  ];

  let butuhNopOptions = [
    {
      label: "0 - Tidak muncul inputan NOP",
      id: 0,
    },
    {
      label: "1 - Muncul inputan NOP",
      id: 1,
    },
    {
      label: "2 - ",
      id: 2,
    },
  ];

  let butuhNoskOptions = [
    {
      label: "0 - Tidak muncul inputan Nomor Ketetapan",
      id: 0,
    },
    {
      label: "1 - Muncul inputan Nomor Ketetapan",
      id: 1,
    },
  ];

  useEffect(() => {
    getJenisSetoransPagination();
    id && getJenisSetoranById();
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [id, page, searchTerm]);

  const getJenisSetoransPagination = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/jenisSetoransPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setJenisSetoransPagination(response.data.jenisSetorans);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getJenisSetorans = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/jenisSetorans`, {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setJenisSetorans(response.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getJenisSetoranById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/jenisSetorans/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setKodeJenisPajak(
        `${response.data.jenispajak.kodeJenisPajak} - ${response.data.jenispajak.namaJenisPajak}`
      );
      setKodeJenisSetoran(response.data.kodeJenisSetoran);
      setNamaJenisSetoran(response.data.namaJenisSetoran);
      setKetJenisSetoran(response.data.ketJenisSetoran);

      let tempMasaBulan = masaBulanOptions.find((data) => {
        return response.data.masaBulan == data.id;
      });
      setMasaBulan(tempMasaBulan.label);

      let tempMasaTahun = masaTahunOptions.find((data) => {
        return response.data.masaTahun == data.id;
      });
      setMasaTahun(tempMasaTahun.label);

      let tempMataUang = mataUangOptions.find((data) => {
        return response.data.mataUang == data.id;
      });
      setMataUang(tempMataUang.label);

      let tempWpBadan = wpBadanOptions.find((data) => {
        return response.data.wpBadan == data.id;
      });
      setWpBadan(tempWpBadan.label);

      let tempWpPemungut = wpPemungutOptions.find((data) => {
        return response.data.wpPemungut == data.id;
      });
      setWpPemungut(tempWpPemungut.label);

      let tempWpOp = wpOpOptions.find((data) => {
        return response.data.wpOp == data.id;
      });
      setWpOp(tempWpOp.label);

      let tempNpwpNol = npwpNolOptions.find((data) => {
        return response.data.npwpNol == data.id;
      });
      setNpwpNol(tempNpwpNol.label);

      let tempNpwpLain = npwpLainOptions.find((data) => {
        return response.data.npwpLain == data.id;
      });
      setNpwpLain(tempNpwpLain.label);

      let tempButuhNop = butuhNopOptions.find((data) => {
        return response.data.butuhNop == data.id;
      });
      setButuhNop(tempButuhNop.label);

      let tempButuhNosk = butuhNoskOptions.find((data) => {
        return response.data.butuhNosk == data.id;
      });
      setButuhNosk(tempButuhNosk.label);
    }
  };

  const deleteJenisSetoran = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteJenisSetoran/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setSearchTerm("");
      setKodeJenisPajak("");
      setNamaJenisSetoran("");
      setKodeJenisSetoran("");
      setKetJenisSetoran("");
      setMasaBulan("");
      setMasaTahun("");
      setMataUang("");
      setWpBadan("");
      setWpPemungut("");
      setWpOp("");
      setNpwpNol("");
      setNpwpLain("");
      setButuhNop("");
      setButuhNosk("");
      navigate("/jenisSetoran");
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(`${namaJenisSetoran} tidak bisa dihapus karena sudah ada data!`);
      }
    }
    setLoading(false);
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.text(
      `${setting.namaPerusahaan} - ${setting.kabupatenPerusahaan}`,
      15,
      10
    );
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(14);
    doc.text(`Daftar Jenis Setoran`, 90, 30);
    doc.setFontSize(8);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      styles: {
        fontSize: 8,
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "JenisSetoran",
    sheet: "DaftarJenisSetoran",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container ref={ref}>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar Jenis Setoran</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              getJenisSetorans();
              setPreviewPdf(!previewPdf);
              setPreviewExcel(false);
            }}
          >
            PDF
          </Button>
          <Button
            color="secondary"
            startIcon={<SearchIcon />}
            onClick={() => {
              getJenisSetorans();
              setPreviewExcel(!previewExcel);
              setPreviewPdf(false);
            }}
          >
            Excel
          </Button>
        </ButtonGroup>
      </Box>
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="table" id="table">
            <thead>
              <tr>
                <th>Jenis Pajak</th>
                <th>Kode</th>
                <th>Nama</th>
                <th>Ket</th>
              </tr>
            </thead>
            <tbody>
              {jenisSetorans.map((user, index) => (
                <tr key={user.id}>
                  <td>{`${user.jenispajak.kodeJenisPajak} - ${user.jenispajak.namaJenisPajak}`}</td>
                  <td>{user.kodeJenisSetoran}</td>
                  <td>{user.namaJenisSetoran}</td>
                  <td>{user.ketJenisSetoran}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        )}
        <table ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th>Jenis Pajak</th>
                <th>Kode</th>
                <th>Nama</th>
                <th>Ket</th>
              </tr>
              {jenisSetorans.map((user, index) => (
                <tr key={user.id}>
                  <td>{`${user.jenispajak.kodeJenisPajak} - ${user.jenispajak.namaJenisPajak}`}</td>
                  <td>{user.kodeJenisSetoran}</td>
                  <td>{user.namaJenisSetoran}</td>
                  <td>{user.ketJenisSetoran}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={id}
          addLink={`/jenisSetoran/tambahJenisSetoran`}
          editLink={`/jenisSetoran/${id}/edit`}
          deleteUser={deleteJenisSetoran}
          nameUser={kodeJenisSetoran}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Card>
              <Card.Header>Jenis Setoran</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode Jenis Pajak :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={kodeJenisPajak}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kode :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={kodeJenisSetoran}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          rows={4}
                          value={namaJenisSetoran}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          rows={4}
                          value={ketJenisSetoran}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card style={spacingTop}>
              <Card.Header>Kondisi Setoran</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Masa Bulan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={masaBulan} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Masa Tahun :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={masaTahun} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Mata Uang :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={mataUang} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Wp Badan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={wpBadan} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Wp Pemungut :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={wpPemungut} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Wp Op :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={wpOp} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Npwp Nol :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={npwpNol} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Npwp Lain :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={npwpLain} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Butuh Nop :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={butuhNop} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Butuh Nosk :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={butuhNosk} disabled readOnly />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        </Container>
      )}
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableJenisSetoran currentPosts={jenisSetoransPagination} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilJenisSetoran;

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const spacingTop = {
  marginTop: "20px",
};
