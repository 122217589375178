import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { useStateContext } from "../../../contexts/ContextProvider";
import { Container, Form, Row, Col } from "react-bootstrap";
import { Button, ButtonGroup, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const ProfilUser = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  return (
    <Container>
      <h3>Utility</h3>
      <h5 style={{ fontWeight: 400 }}>Profil User</h5>
      <Container className="d-flex justify-content-center">
        <ButtonGroup variant="contained">
          <Button
            color="primary"
            startIcon={<EditIcon />}
            sx={{ textTransform: "none" }}
            onClick={() => {
              navigate(`/profilUser/${user.id}/edit`);
            }}
          >
            Ubah Password
          </Button>
        </ButtonGroup>
      </Container>
      <hr />
      <Container>
        <Form>
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Nama :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={user.nama} disabled readOnly />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tipe User :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={user.tipeUser} disabled readOnly />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>

            <Box sx={[textFieldWrapper, secondWrapper]}></Box>
          </Box>
        </Form>
      </Container>
    </Container>
  );
};

export default ProfilUser;

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};
